.common-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.common-content {
    max-width: 100vw;
    min-width: 80%;
    margin: 0px auto;
    padding: 40px 10px 60px;
}

.common-footer {
    width: 100%;
    padding: 10px;
    display: flex;
    color: var(--main-bg-color);
    background-color: var(--main-font-color);
    justify-content: center;
    position: fixed;
    bottom: 0;
}
