/* FORM */
button {
    background-color: var(--main-font-color);
    color: var(--main-bg-color);
    border-radius: 6px;
    padding: 6px 10px;
    cursor: pointer;
  }
  button:hover {
    box-shadow: 0px 0px 5px var(--box-shadow-color);
  }
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  select {
    padding: 6px 10px;
    border-radius: 6px;
    min-width: 120px;
  }