/* MARGINS */
.mt-1 {
    margin-top: 0.5rem;
}
.mt-2 {
    margin-top: 1rem;
}
.mt-3 {
    margin-top: 1.5rem;
}
.mt-4 {
    margin-top: 2rem;
}
.mb-1 {
    margin-bottom: 0.5rem;
}
.mb-2 {
    margin-bottom: 1rem;
}
.mb-3 {
    margin-bottom: 1.5rem;
}
.mb-4 {
    margin-bottom: 2rem;
}

/* PADDINGS */
.pt-1 {
    padding-top: 0.5rem;
}
.pt-2 {
    padding-top: 1rem;
}
.pt-3 {
    padding-top: 1.5rem;
}
.pt-4 {
    padding-top: 2rem;
}
.pb-1 {
    padding-bottom: 0.5rem;
}
.pb-2 {
    padding-bottom: 1rem;
}
.pb-3 {
    padding-bottom: 1.5rem;
}
.pb-4 {
    padding-bottom: 2rem;
}
.w-100 {
    width: 100%;
}
.w-75 {
    width: 75%;
}
.w-50 {
    width: 50%;
}
.w-25 {
    width: 25%;
}