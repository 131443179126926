@import url('./styles/form-elements.css');
@import url('./styles/margins-and-paddings.css');
@import url('./styles/table.css');
@import url('./styles/text.css');
@import url('./styles/variables.css');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--main-font-color);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  font-size: 0.7rem;
  display: block;
  padding: 20px;
  border-radius: 12px;
  background-color: pink;
  color: red;
}
